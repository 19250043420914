<template>
    <div class="logon">
      <vk-card type="primary">
      <center>
        <h2>Planet CR GIS<br>
        <img src="./assets/planetcr_gis_icon.png" width=256 height=256><br></h2>
        <form>
        <div class="uk-inline">
          <input type="text" v-model="input.username" class="uk-input" placeholder="Username" required autofocus>
        </div><br><br>
        <div class="uk-inline">
          <input type="password" v-model="input.password" class="uk-input" placeholder="Password" @keyup.enter="prepareLoginPost()" required>
        </div><br>
        <!--<input type="checkbox" class="uk-checkbox" value="remember-me"> Remember me-->
        <br><br>
        
        <vk-button type="primary" v-on:click="prepareLoginPost()">Logon</vk-button><br><br>
        <div uk-alert><vk-label type="warning">{{ statusText }}</vk-label></div>
        <a href="#" @click="showForgotPw = true">I Forgot my Password</a>
        </form>
        <hr width=25%>
        This computer system is the property of Planet CR. It is for authorized use only.<br>
        By using this system, all users acknowledge notice of, and agree to comply with, <br>
        Planet CR's <a href="https://www.planetcr.com/privacy-policy/">Privacy Policy</a>.
        <br><br>
      </center>
      </vk-card>
      <vk-modal :show.sync="showExpiredPw">
        <vk-modal-close @click="showExpiredPw = false"></vk-modal-close>
          <vk-modal-title>Reset Password Expired</vk-modal-title>
          <form>
          <div class="uk-flex-center">
          <div class=".uk-flex-none">Your password has expired and must be changed before you can log in.  <br>Please change your password:<br><br></div>
          <div class=".uk-flex-none">Current Password:<br><input class="uk-input uk-form-width-medium" type="password" v-model="userForm.password" @keyup.enter="updatePassword()" required></div><br>
          <div class=".uk-flex-none">New Password:<br><input class="uk-input uk-form-width-medium" type="password" v-model="userForm.newPassword1" @keyup.enter="updatePassword()" required></div>
          <div class=".uk-flex-none">Confirm:<br><input class="uk-input uk-form-width-medium" type="password" v-model="userForm.newPassword2" @keyup.enter="updatePassword()" required ></div>
          <br><div uk-alert><vk-label type="warning">{{ expPwStatusText }}</vk-label></div><br>
          <vk-button type="primary" @click="updatePassword">Update</vk-button> <vk-button type="secondary" @click="showExpiredPw = false">Cancel</vk-button><br><br>
          </div>
          </form>
      </vk-modal>
      <vk-modal :show.sync="showForgotPw">
        <vk-modal-close @click="showForgotPw = false"></vk-modal-close>
          <vk-modal-title>Forgot Password</vk-modal-title>
          <form>
          <div class="uk-flex-center">
            If you have an account, a password reset code will be emailed to you.<br>
            Please follow the directions in that email to finish resetting your password.<br><br>
            <i>Password reset codes are valid for 30 minutes, <br>after which you can request a new reset code.</i><br><br>
            <div class=".uk-flex-left">Username:<br><input class="uk-input uk-form-width-medium" type="text" v-model="forgotPwForm.username" @keyup.enter="generateCode()" required><br>
            <div uk-alert><vk-label type="warning">{{ resetPwStatusText }}</vk-label></div><br>
            <vk-button type="primary" @click="generateCode">Generate Code</vk-button></div><br>
          </div>
          </form>
      </vk-modal>
      <vk-modal :show.sync="showResetPw">
        <vk-modal-close @click="showResetPw = false"></vk-modal-close>
          <vk-modal-title>Forgot Password:</vk-modal-title>
          <form>
          <div class="uk-flex-center">
            Please finish the password reset process by entering a new password below.<br><br><br>
            <div class=".uk-flex-left">Username:<br>{{resetPwForm.username}}<br><br>
          </div>
          <div class="uk-flex-center">
            <div class=".uk-flex-none">New Password:<br><input class="uk-input uk-form-width-medium" type="password" v-model="resetPwForm.newPassword1" @keyup.enter="resetPassword()" required></div>
          </div>
          <div class="uk-flex-center">
            <div class=".uk-flex-none">Confirm:<br><input class="uk-input uk-form-width-medium" type="password" v-model="resetPwForm.newPassword2" @keyup.enter="resetPassword()" required></div>
          </div>
          <br><div uk-alert><vk-label type="warning">{{ confirmResetPwStatusText }}</vk-label></div><br>
          <vk-button type="primary" @click="resetPassword">Reset</vk-button> <vk-button type="secondary" @click="showResetPw = false">Cancel</vk-button><br><br>
          </div>
          </form>
      </vk-modal>
    </div> 
</template>

<script>
    import axios from "axios";
    import Vue from 'vue'
    import Vuikit from 'vuikit'
    import VuikitIcons from '@vuikit/icons'
    import '@vuikit/theme'

    Vue.use(Vuikit)
    Vue.use(VuikitIcons)
    export default {
        created()
        {
          this.getURLparameters()
        },
        name: 'logon',
        data () {
            return {
              expPwStatusText: "",
              resetPwStatusText: "",
              confirmResetPwStatusText: "",
              userForm: {
                request_type: "changePw",
                password: "",
                newPassword1: "",
                newPassword2: ""
              },
              forgotPwForm: {
                request_type: "forgotPw",
                username: ""
              },
              resetPwForm: {
                request_type: "forgotPwConfirm",
                code: "",
                username: "",
                new_password: "",
                newPassword1: "",
                newPassword2: ""
              },
              showExpiredPw: false,
              showForgotPw: false,
              showResetPw: false, 
              statusText: "",
              input: {
                  request_type: "logon",
                  username: "",
                  password: ""
              },
              response: "",
              errorText: ""
            }
        },
        mounted() {
           return axios.get(`/api/v2/auth/index.php`).then(response => {
            if (typeof response.data.session != "undefined"){
              if (response.data.session == "valid"){
                this.statusText = "Authentication successful...";
                window.location = "/table.html";
              }
            }
          })
        },
        methods: {
          getURLparameters(){
            let uri = window.location.href.split('?');
            if (uri.length == 2)
            {
              let vars = uri[1].split('&');
              let getVars = {};
              let tmp = '';
              vars.forEach(function(v){
                tmp = v.split('=');
                if(tmp.length == 2)
                getVars[tmp[0]] = tmp[1];
              });
              if (typeof getVars.username != "undefined"){
                this.resetPwForm.username = getVars.username;
              }
              if (typeof getVars.passwordResetCode != "undefined"){
                this.resetPwForm.code = getVars.passwordResetCode;
                this.showResetPw = true;
              }
              console.log(getVars);
              // do 
              }
          },
          prepareLoginPost(){
            var logonStatus = ""
            this.makePOST(this.input).then((data) =>{
              logonStatus = data;
              if (typeof logonStatus.error != "undefined"){
                if (logonStatus.error == "Authentication failed"){
                    this.statusText = "Authentication failed, please try again...";
                }
              }
              else {
                  if (logonStatus.authStatus == "authenticated"){
                    this.statusText = "Authentication successful...";
                    document.cookie = "PlanetCR_IgnoreGeocodeWarning = 0"
                    window.location = "/table.html";
                }
                if (logonStatus.authStatus == "Password expired"){
                    this.statusText = "Your password has expired, please contact support...";
                    this.showExpiredPw = true;
                    //window.location = "/table.html";
                }
              }
            });
          },
          updatePassword() {
            if (this.userForm.newPassword1 == this.userForm.newPassword2){
              this.userForm.username = this.input.username
              this.userForm.new_password = this.userForm.newPassword1
              this.makePOST(this.userForm).then((data) =>{
                var passwordStatus = data
                if (typeof passwordStatus.error != "undefined"){
                  if (passwordStatus.error == "Authentication failed"){
                      this.expPwStatusText = "Old password is incorrect, please try again..."
                  }
                }
                else {
                  if ('authStatus' in passwordStatus){
                    this.showExpiredPw = false
                    this.statusText = "Passwords updated... Please log in with new password"
                  }
                  if ('matchingPws' in passwordStatus){
                    //this.userForm.apikey = apiStatus.newApiKey
                    this.expPwStatusText = "New password and old password are the same..."
                  }
                }
              })
            }
            else {
              this.expPwStatusText = "Passwords don't match..."
            }
          },
          resetPassword() {
            if (this.resetPwForm.newPassword1 == ""){
              this.confirmResetPwStatusText = "Password cannot be blank..."
            }
            else if (this.resetPwForm.newPassword1 == this.resetPwForm.newPassword2){
              this.resetPwForm.new_password = this.resetPwForm.newPassword1
              this.makePOST(this.resetPwForm).then((data) =>{
                var passwordStatus = data
                if (typeof passwordStatus.error != "undefined"){
                  if (passwordStatus.error == "Authentication failed"){
                      this.confirmResetPwStatusText = "Something went wrong.  Please generate a new code and try again..."
                  }
                }
                else {
                  if ('authStatus' in passwordStatus){
                    this.showResetPw = false
                    this.statusText = "Passwords updated... Please log in with new password"
                  }
                }
              })
            }
            else {
              this.confirmResetPwStatusText = "Passwords don't match..."
            }
          },
          generateCode() {
            var passwordResetStatus = ""
            if (this.forgotPwForm.username == ""){
              this.resetPwStatusText = "Please enter a username..."
            }
            else {
              this.makePOST(this.forgotPwForm).then((data) => {
                passwordResetStatus = data
                if (typeof passwordResetStatus.authStatus != "undefined"){
                  this.resetPwStatusText = passwordResetStatus.authStatus
                }
              })
            }
          },
          sendData() {
              var logonStatus = [];
                axios({ method: "POST", "url": "/api/v2/auth/index.php", "data": this.input, 
                "headers": { "content-type": "application/json" } }).then(result => 
                {
                    logonStatus = result.data;
                    if (typeof logonStatus.error != "undefined"){
                      if (logonStatus.error == "Authentication failed"){
                          this.statusText = "Authentication failed, please try again...";
                      }
                    }
                    else {
                        if (logonStatus.authStatus == "authenticated"){
                          this.statusText = "Authentication successful...";
                          document.cookie = "PlanetCR_IgnoreGeocodeWarning = 0"
                          window.location = "/table.html";
                      }
                      if (logonStatus.authStatus == "Password expired"){
                          this.statusText = "Your password has expired, please contact support...";
                          this.showExpiredPw = true;
                          //window.location = "/table.html";
                      }
                    }
                }, error => {
                    console.error(error);
                });
          },
          
          makePOST(postData) {
            return axios({ method: "POST", "url": "/api/v2/auth/index.php", "data": postData, "headers": { "content-type": "application/json" } }).then(response => response.data);
          },
    }
  }
    
</script>
<style scoped>
.logon {
  text-align: center;
  margin: auto;
  width: 75%;
  padding: 10px;
}
</style>